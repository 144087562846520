*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background: #3b71ca;
  border: 0;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #a3a3a3;
}

input[type="range"]:disabled:focus::-webkit-slider-thumb {
  background: #a3a3a3;
}

input[type="range"]:disabled:active::-webkit-slider-thumb {
  background: #a3a3a3;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  cursor: pointer;
  background: #3b71ca;
  border: 0;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #a3a3a3;
}

input[type="range"]::-moz-range-progress {
  background: #3061af;
}

input[type="range"]::-ms-fill-lower {
  background: #3061af;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
  background: #3061af;
}

input[type="range"]:active::-webkit-slider-thumb {
  background: #285192;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 320px) {
  .container {
    max-width: 320px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.\!absolute {
  position: absolute !important;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.-right-2 {
  right: -.5rem;
}

.-top-2 {
  top: -.5rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.top-0 {
  top: 0;
}

.top-1\/3 {
  top: 33.3333%;
}

.z-\[1055\] {
  z-index: 1055;
}

.z-\[2055\] {
  z-index: 2055;
}

.z-\[3000\] {
  z-index: 3000;
}

.\!-m-px {
  margin: -1px !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-auto {
  margin-top: auto;
}

.box-content {
  box-sizing: content-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.\!h-px {
  height: 1px !important;
}

.h-10 {
  height: 2.5rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-\[auto\] {
  min-height: auto;
}

.min-h-\[calc\(100\%-1rem\)\] {
  min-height: calc(100% - 1rem);
}

.w-10\/12 {
  width: 83.3333%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-full {
  width: 100%;
}

.\!w-px {
  width: 1px !important;
}

.w-10 {
  width: 2.5rem;
}

.w-auto {
  width: auto;
}

.w-screen {
  width: 100vw;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.translate-y-\[-50px\] {
  --tw-translate-y: -50px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.animate-\[spinner-grow_0\.75s_linear_infinite\] {
  animation: .75s linear infinite spinner-grow;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.\!overflow-hidden {
  overflow: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.\!whitespace-nowrap {
  white-space: nowrap !important;
}

.text-wrap {
  text-wrap: wrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.\!border-0 {
  border-width: 0 !important;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-none {
  border-style: none;
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202 / var(--tw-bg-opacity));
}

.bg-clip-padding {
  background-clip: padding-box;
}

.p-2 {
  padding: .5rem;
}

.p-\[0\.25rem\] {
  padding: .25rem;
}

.\!p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-\[0\.32rem\] {
  padding-top: .32rem;
  padding-bottom: .32rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.text-center {
  text-align: center;
}

.align-\[-0\.125em\] {
  vertical-align: -.125em;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-\[0\.1rem\] {
  line-height: .1rem;
}

.leading-\[1\.6\] {
  line-height: 1.6;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-\[0\.01rem\] {
  letter-spacing: .01rem;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-primary-700 {
  --tw-text-opacity: 1;
  color: rgb(40 81 146 / var(--tw-text-opacity));
}

.text-surface {
  --tw-text-opacity: 1;
  color: rgb(79 79 79 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-20 {
  opacity: .2;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-4 {
  --tw-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  --tw-shadow-colored: 0 2px 15px -3px var(--tw-shadow-color), 0 10px 20px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-primary-3 {
  --tw-shadow: 0 4px 9px -4px #3b71ca;
  --tw-shadow-colored: 0 4px 9px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-gray-700 {
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-violet-700 {
  outline-color: #6d28d9;
}

.outline-gray-400 {
  outline-color: #9ca3af;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\!\[clip\:rect\(0\,0\,0\,0\)\] {
  clip: rect(0, 0, 0, 0) !important;
}

.progress-container {
  justify-content: center;
  height: .095rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.progress {
  width: .65rem;
  height: .65rem;
  animation: 8s linear infinite moveProgress;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@keyframes moveProgress {
  0% {
    left: 0;
  }

  12.5% {
    left: calc(25% - 5px);
  }

  25% {
    left: calc(50% - 5px);
  }

  37.5% {
    left: calc(75% - 5px);
  }

  50% {
    left: calc(100% - 5px);
  }

  62.5% {
    left: calc(75% - 5px);
  }

  75% {
    left: calc(50% - 5px);
  }

  87.5% {
    left: calc(25% - 5px);
  }

  100% {
    left: 0;
  }
}

.hover\:bg-primary-accent-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(206 219 238 / var(--tw-bg-opacity));
}

.hover\:bg-primary-accent-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(56 107 192 / var(--tw-bg-opacity));
}

.hover\:text-neutral-800:hover {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:shadow-primary-2:hover {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:bg-primary-accent-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(206 219 238 / var(--tw-bg-opacity));
}

.focus\:bg-primary-accent-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(56 107 192 / var(--tw-bg-opacity));
}

.focus\:text-neutral-800:focus {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.focus\:opacity-100:focus {
  opacity: 1;
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-primary-2:focus {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:placeholder\:opacity-100:focus::placeholder {
  opacity: 1;
}

.active\:bg-primary-600:active {
  --tw-bg-opacity: 1;
  background-color: rgb(48 97 175 / var(--tw-bg-opacity));
}

.active\:bg-primary-accent-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(206 219 238 / var(--tw-bg-opacity));
}

.active\:shadow-primary-2:active {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (prefers-reduced-motion: reduce) {
  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: none;
    }
  }

  .motion-reduce\:animate-\[spinner-grow_1\.5s_linear_infinite\] {
    animation: 1.5s linear infinite spinner-grow;
  }

  .motion-reduce\:transition-none {
    transition-property: none;
  }
}

@media (width >= 0) {
  .min-\[0px\]\:m-0 {
    margin: 0;
  }

  .min-\[0px\]\:h-full {
    height: 100%;
  }

  .min-\[0px\]\:max-w-none {
    max-width: none;
  }

  .min-\[0px\]\:overflow-y-auto {
    overflow-y: auto;
  }

  .min-\[0px\]\:rounded-none {
    border-radius: 0;
  }

  .min-\[0px\]\:border-0 {
    border-width: 0;
  }
}

@media (width >= 576px) {
  .min-\[576px\]\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .min-\[576px\]\:mt-7 {
    margin-top: 1.75rem;
  }

  .min-\[576px\]\:min-h-\[calc\(100\%-3\.5rem\)\] {
    min-height: calc(100% - 3.5rem);
  }

  .min-\[576px\]\:max-w-\[500px\] {
    max-width: 500px;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-white\/10 {
    border-color: #ffffff1a;
  }

  .dark\:bg-primary-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(171 194 232 / var(--tw-bg-opacity));
  }

  .dark\:bg-surface-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(66 66 66 / var(--tw-bg-opacity));
  }

  .dark\:text-neutral-400 {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity));
  }

  .dark\:shadow-black\/30 {
    --tw-shadow-color: #0000004d;
    --tw-shadow: var(--tw-shadow-colored);
  }

  .dark\:hover\:bg-primary-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(143 174 224 / var(--tw-bg-opacity));
  }

  .dark\:hover\:text-neutral-300:hover {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity));
  }

  .dark\:hover\:shadow-dark-strong:hover {
    --tw-shadow: 0 4px 18px -2px #000000b3;
    --tw-shadow-colored: 0 4px 18px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark\:focus\:bg-primary-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(143 174 224 / var(--tw-bg-opacity));
  }

  .dark\:focus\:text-neutral-300:focus {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity));
  }

  .dark\:focus\:shadow-dark-strong:focus {
    --tw-shadow: 0 4px 18px -2px #000000b3;
    --tw-shadow-colored: 0 4px 18px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark\:active\:bg-primary-400:active {
    --tw-bg-opacity: 1;
    background-color: rgb(143 174 224 / var(--tw-bg-opacity));
  }

  .dark\:active\:shadow-dark-strong:active {
    --tw-shadow: 0 4px 18px -2px #000000b3;
    --tw-shadow-colored: 0 4px 18px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.\[\&\>svg\]\:h-4 > svg {
  height: 1rem;
}

.\[\&\>svg\]\:h-6 > svg {
  height: 1.5rem;
}

.\[\&\>svg\]\:w-4 > svg {
  width: 1rem;
}

.\[\&\>svg\]\:w-6 > svg {
  width: 1.5rem;
}
/*# sourceMappingURL=index.48ca0a9a.css.map */
